.App {
  position: relative;;
  display: flex;
  width: 500px;
}

.left {
  flex-direction: column;
  position: fixed;
  display: flex;
  width: 600px;
  left: 50px;
  top: 0;
  
}
.right {
  position: relative;
  left: 700px;
}

:focus {
  outline: none;
}


